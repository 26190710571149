var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"list-inline-item border rounded p-2 mt-1 tag-item light-grey-bg\"\n    data-controller=\"remove-filter-tag\"\n    data-remove-filter-tag-text-value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"filterValue") || (depth0 != null ? lookupProperty(depth0,"filterValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filterValue","hash":{},"data":data,"loc":{"start":{"line":3,"column":39},"end":{"line":3,"column":54}}}) : helper)))
    + "\"\n    data-remove-filter-tag-input-text-value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"inputText") || (depth0 != null ? lookupProperty(depth0,"inputText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputText","hash":{},"data":data,"loc":{"start":{"line":4,"column":45},"end":{"line":4,"column":58}}}) : helper)))
    + "\">\n  <span class=\"p-2 mt-1\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"filterValue") || (depth0 != null ? lookupProperty(depth0,"filterValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filterValue","hash":{},"data":data,"loc":{"start":{"line":5,"column":25},"end":{"line":5,"column":40}}}) : helper)))
    + "</span>\n  <button type=\"button\"\n          class=\"close btn-circle\"\n          aria-label=\"Remove the "
    + alias4(((helper = (helper = lookupProperty(helpers,"filterValue") || (depth0 != null ? lookupProperty(depth0,"filterValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filterValue","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":48}}}) : helper)))
    + " filter\"\n          data-controller=\"google-analytics-events\"\n          data-google-analytics-events-category=\"Filter\"\n          data-google-analytics-events-action=\"De-select Filter Tag\"\n          data-google-analytics-events-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"googleAnalyticsLabel") || (depth0 != null ? lookupProperty(depth0,"googleAnalyticsLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"googleAnalyticsLabel","hash":{},"data":data,"loc":{"start":{"line":12,"column":46},"end":{"line":12,"column":70}}}) : helper)))
    + "\"\n          data-action=\"google-analytics-events#trackEvent remove-filter-tag#remove\">\n    <span aria-hidden=\"true\">&times;</span>\n  </button>\n</li>\n";
},"useData":true});