import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "row",
    "table"
  ];

  restripe() {
    this.tableTarget.classList.remove("table-striped");

    this.visibleRows.forEach((row, idx) => {
      if (idx % 2 === 0) {
        row.classList.add("odd");
      } else {
        row.classList.remove("odd");
      }
    });
  }

  get visibleRows() {
    return this.rowTargets.filter(row => row.hidden === false);
  }
}
