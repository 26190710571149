import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "allInputsContainer",
    "input",
    "inputContainer",
    "pinnedContainer",
    "reset",
    "searchBox",
    "showAll",
    "showLess",
    "unPinnedContainer"
  ]

  connect() {
    this.showLess();
    const newFilters = new CustomEvent("new-filters", { bubbles: true });
    this.element.dispatchEvent(newFilters);
  }

  search() {
    this.turnOffOverflow();
    this.typeAhead();
  }

  typeAhead() {
    const searchCriteriaInUpperCase = this.searchBoxTarget.value.toUpperCase();

    let optionLabelText;

    try {
      this.inputContainerTargets.forEach(option => {
        optionLabelText = option.querySelector("label span.label-context").nextSibling.textContent.trim().toUpperCase();

        if (optionLabelText.includes(searchCriteriaInUpperCase)) {
          option.hidden = false;
        } else {
          option.hidden = true;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  filter(event) {
    this.clearInactiveFilterSubsets(event.srcElement);
    this.filterChanged();
  }

  clearInactiveFilterSubsets(element) {
    const thisGroup = element.dataset.filterInputSubset;
    this.inputTargets
      .filter(target => target.dataset.filterInputSubset !== thisGroup)
      .forEach((target) => { target.checked = false; });

    this.clearAnyCheckbox();
  }

  clearAnyCheckbox() {
    // could this folded into a subset for any ?
    this.resetTarget.checked = false;
  }

  reset() {
    this.inputTargets
      .forEach((target) => { target.checked = false; });

    this.resetTarget.checked = true;

    this.filterChanged();
  }

  resetElement(event) {
    const foundElement = this.inputTargets.find((input) => input.dataset.inputName === event.detail.inputText);
    foundElement.checked = false;
    this.clearInactiveFilterSubsets(foundElement);
    this.filterChanged();
  }

  filterChanged() {
    this.resortOptions();
    this.resetSearch();
    this.sendFilterChangedEvent();
  }

  resetSearch() {
    this.searchBoxTarget.value = "";
    this.typeAhead();
    this.restoreOverflow();
  }

  restoreOverflow() {
    if (this.isViewingAll()) {
      this.showAll();
    } else {
      this.showLess();
    }
  }

  isViewingAll() {
    return this.showAllTarget.hidden;
  }

  resortOptions() {
    const allInputContainers = this.inputContainerTargets;
    allInputContainers.forEach((inputContainer) => {
      inputContainer.remove();
    });

    const sortedCheckedInputs = allInputContainers
      .filter(inputContainer => this.inputContainerChecked(inputContainer) === true)
      .sort(this.sortFilterInputContainer);
    this.pinnedContainerTarget.prepend(...sortedCheckedInputs);

    const sortedUnCheckedInputs = allInputContainers
      .filter(inputContainer => this.inputContainerChecked(inputContainer) === false)
      .sort(this.sortFilterInputContainer);
    this.unPinnedContainerTarget.prepend(...sortedUnCheckedInputs);

    if (sortedCheckedInputs.length > 0) {
      this.pinnedContainerTarget.classList.add("pinned-options");
    } else if (sortedCheckedInputs.length === 0) {
      this.pinnedContainerTarget.classList.remove("pinned-options");
    }
  }

  inputContainerChecked(inputContainer) {
    return inputContainer.querySelector("input").checked;
  }

  sortFilterInputContainer(a, b) {
    const aSortValue = a.dataset.filterInputSortValue;
    const bSortValue = b.dataset.filterInputSortValue;

    if (aSortValue < bSortValue) {
      return -1;
    }
    if (aSortValue > bSortValue) {
      return 1;
    }
    return 0;
  }

  sendFilterChangedEvent() {
    const filterChanged = new CustomEvent("filter-changed", {
      bubbles: true,
      detail: {
        name: this.filterName,
        state: this.filterState
      }
    });

    this.element.dispatchEvent(filterChanged);
  }

  showAll() {
    if (this.hasOverflow) {
      this.turnOffOverflow();
      this.showAllTarget.hidden = true;
      this.showLessTarget.hidden = false;
    }
  }

  turnOffOverflow() {
    this.inputContainerTargets.forEach(inputContainer => inputContainer.classList.remove("overflow"));
  }

  showLess() {
    if (this.hasOverflow) {
      this.overflow.forEach(inputContainer => inputContainer.classList.add("overflow"));
      this.showAllTarget.hidden = false;
      this.showLessTarget.hidden = true;
    }
  }

  openCollapsedFilters(event) {
    this.collapsedFilters.collapse("show");
    this.resetTarget.checked = false;
    this.showAll();
    this.resortOptions();
  }

  get collapsedFilters() {
    return $(this.filterCheckboxesContainer);
  }

  get filterState() {
    return this.inputTargets
      .filter((target) => target.checked)
      .map((target) => target.dataset.inputName);
  }

  get filterName() {
    return this.data.get("name");
  }

  get filterCheckboxesContainer() {
    return this.data.get("filterContainerSelector");
  }

  get limit() {
    return Number(this.data.get("max"));
  }

  get overflow() {
    return Array.from(this.unPinnedContainerTarget.querySelectorAll(".form-check"))
      .slice(this.limit);
  }

  get hasOverflow() {
    return this.overflow.length > 0;
  }
}
