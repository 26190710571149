import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "selector"
  ]

  sort() {
    if (this.selectedSort === "Capacity (Low to High)") {
      this.capacityLowToHighSort();
    } else if (this.selectedSort === "Capacity (Low to High)") {
      this.capacityHighToLowSort();
    } else if (this.selectedSort === "Location") {
      this.locationSort();
    } else {
      this.buildingAZSort();
    }

    const roomsChanged = new CustomEvent("rooms-changed", { bubbles: true });
    this.element.dispatchEvent(roomsChanged);
  }

  locationSort() {
    this.resultsCardDiv.innerHTML = this.roomCardsSortedByLocation.map(node => node.outerHTML).join("");
    this.resultsCardTable.innerHTML = this.roomTableSortedByLocation.map(node => node.outerHTML).join("");
  }

  capacityLowToHighSort() {
    const resultsCardDiv = document.getElementById("results_cards");
    const sortedCardsHtml = this.roomCardsSortedByCapacityLowToHigh.map(node => node.outerHTML).join("");
    resultsCardDiv.innerHTML = sortedCardsHtml;

    const resultsCardTable = document.getElementById("room-list").querySelector("table > tbody");
    const sortedTableHtml = this.roomTableSortedByCapacityLowToHigh.map(node => node.outerHTML).join("");
    resultsCardTable.innerHTML = sortedTableHtml;
  }

  capacityHighToLowSort() {
    const resultsCardDiv = document.getElementById("results_cards");
    const sortedCardsHtml = this.roomCardsSortedByCapacityHighToLow.map(node => node.outerHTML).join("");
    resultsCardDiv.innerHTML = sortedCardsHtml;

    const resultsCardTable = document.getElementById("room-list").querySelector("table > tbody");
    const sortedTableHtml = this.roomTableSortedByCapacityHighToLow.map(node => node.outerHTML).join("");
    resultsCardTable.innerHTML = sortedTableHtml;
  }

  buildingAZSort() {
    const resultsCardDiv = document.getElementById("results_cards");
    const sortedCardsHtml = this.roomCardsSortedByBuildingAZ.map(node => node.outerHTML).join("");
    resultsCardDiv.innerHTML = sortedCardsHtml;

    const resultsCardTable = document.getElementById("room-list").querySelector("table > tbody");
    const sortedTableHtml = this.roomTableSortedByBuildingAZ.map(node => node.outerHTML).join("");
    resultsCardTable.innerHTML = sortedTableHtml;
  }

  get roomCardsSortedByLocation() {
    const roomNodeList = document.querySelectorAll("#results_cards .room-listing");
    const roomNodesArray = Array.from(roomNodeList);
    return this.sortRooms(this.selectedSort, roomNodesArray);
  }

  get roomTableSortedByLocation() {
    const roomNodeList = document.getElementById("room-list").querySelectorAll("table > tbody > tr");
    const roomNodesArray = Array.from(roomNodeList);
    return this.sortRooms(this.selectedSort, roomNodesArray);
  }

  get roomCardsSortedByCapacityLowToHigh() {
    const roomNodeList = document.querySelectorAll("#results_cards .room-listing");
    const roomNodesArray = Array.from(roomNodeList);
    return this.sortRooms(this.selectedSort, roomNodesArray);
  }

  get roomTableSortedByCapacityLowToHigh() {
    const roomNodeList = document.getElementById("room-list").querySelectorAll("table > tbody > tr");
    const roomNodesArray = Array.from(roomNodeList);
    return this.sortRooms(this.selectedSort, roomNodesArray);
  }

  get roomCardsSortedByCapacityHighToLow() {
    const roomNodeList = document.querySelectorAll("#results_cards .room-listing");
    const roomNodesArray = Array.from(roomNodeList);
    return this.sortRooms(this.selectedSort, roomNodesArray);
  }

  get roomTableSortedByCapacityHighToLow() {
    const roomNodeList = document.getElementById("room-list").querySelectorAll("table > tbody > tr");
    const roomNodesArray = Array.from(roomNodeList);
    return this.sortRooms(this.selectedSort, roomNodesArray);
  }

  get roomCardsSortedByBuildingAZ() {
    const roomNodeList = document.querySelectorAll("#results_cards .room-listing");
    const roomNodesArray = Array.from(roomNodeList);
    return this.sortRooms(this.selectedSort, roomNodesArray);
  }

  get roomTableSortedByBuildingAZ() {
    const roomNodeList = document.getElementById("room-list").querySelectorAll("table > tbody > tr");
    const roomNodesArray = Array.from(roomNodeList);
    return this.sortRooms(this.selectedSort, roomNodesArray);
  }

  sortRooms(sorter, roomNodesArray) {
    if (sorter === "Capacity (Low to High)") {
      return roomNodesArray.sort((a, b) => parseFloat(a.dataset.filterCapacity) - parseFloat(b.dataset.filterCapacity));
    } else if (sorter === "Capacity (High to Low)") {
      return roomNodesArray.sort((a, b) => parseFloat(b.dataset.filterCapacity) - parseFloat(a.dataset.filterCapacity));
    } else if (this.selectedSort === "Location") {
      return roomNodesArray.sort((a, b) => a.dataset.filterLocation.localeCompare(b.dataset.filterLocation));
    } else {
      return roomNodesArray.sort((a, b) => a.dataset.filterSortedName.localeCompare(b.dataset.filterSortedName));
    }
  }

  get selectedSort() {
    return this.selectorTarget.selectedOptions[0].value;
  }

  get resultsCardDiv() {
    return document.getElementById("results_cards");
  }

  get resultsCardTable() {
    return document.getElementById("room-list").querySelector("table > tbody");
  }
}
