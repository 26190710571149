import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "description",
    "grid",
    "list"
  ]

  connect() {
    this.update();
  }

  update() {
    this.updateDescription();
  }

  updateDescription() {
    if (this.visibleRooms.length === 1) {
      this.descriptionTargets.forEach(target => { target.innerHTML = `<strong>${this.visibleRooms.length}</strong> room matches your criteria`; });
    } else {
      this.descriptionTargets.forEach(target => { target.innerHTML = `<strong>${this.visibleRooms.length}</strong> rooms match your criteria`; });
    }
  }

  get rooms() {
    return Array.from(this.currentLayout.querySelectorAll(".room-listing"));
  }

  get visibleRooms() {
    return this.rooms.filter(room => !room.hidden);
  }

  get currentLayout() {
    if (this.inListView) {
      return this.grid;
    } else {
      return this.list;
    }
  }

  get inListView() {
    return this.grid.hidden;
  }

  get grid() {
    return this.gridTarget;
  }

  get list() {
    return this.listTarget;
  }
}
