class SessionState {

  constructor() {
    const savedState = window.sessionStorage.getItem("umnRoomsearchFilters");
    if (savedState !=null) {
      this.filters = (JSON.parse(savedState));
    } else {
      this.filters = this.freshState();
    }
  };

  freshState() {
    return {
      roomType: [],
      location: [],
      capacity: [],
      furnitureType: [],
      building: [],
      feature: []
    };
  }

  get persisted() {
    return !this.isFresh;
  }

  get isFresh() {
    return this.filters.roomType.length === 0 &&
      this.filters.location.length === 0 &&
      this.filters.capacity.length === 0 &&
      this.filters.furnitureType.length === 0 &&
      this.filters.building.length === 0 &&
      this.filters.feature.length === 0;
  }

  saveSessionState(filters) {
    window.sessionStorage.setItem("umnRoomsearchFilters", JSON.stringify(filters));
  }
}

module.exports = SessionState;
