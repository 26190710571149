import { Controller } from "stimulus";
const googleAnalyticsEvent = require("../models/google_analytics_event");

export default class extends Controller {
  static targets = [
    "grid",
    "list",
    "link"
  ];

  switch(event) {
    let googleAnalyticsLabel;

    if (this.inListView) {
      this.showGridView();
      this.changeLinkMessage("Switch to List View");
      googleAnalyticsLabel = "Switch to Grid View";
    } else {
      this.showListView();
      this.changeLinkMessage("Switch to Grid View");
      googleAnalyticsLabel = "Switch to List View";
    }

    const googleAnalyticsParameters = {
      category: "Room List",
      action: "Clicked To Switch Views",
      label: googleAnalyticsLabel
    };

    googleAnalyticsEvent.trackEvent(googleAnalyticsParameters);
  }

  showGridView() {
    this.hide(this.list);
    this.show(this.grid);
  }

  showListView() {
    this.hide(this.grid);
    this.show(this.list);
  }

  changeLinkMessage(message) {
    this.link.textContent = message;
  }

  get inListView() {
    return this.grid.hidden;
  }

  show(element) {
    element.hidden = false;
  }

  hide(element) {
    element.hidden = true;
  }

  get grid() {
    return this.gridTarget;
  }

  get list() {
    return this.listTarget;
  }

  get link() {
    return this.linkTarget;
  }
}
