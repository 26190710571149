import { Controller } from "stimulus";
const googleAnalyticsEvent = require("../models/google_analytics_event");

export default class extends Controller {
  trackEvent(event) {
    googleAnalyticsEvent.trackEvent(this.params);
  }

  get params() {
    return {
      category: this.data.get("category"),
      action: this.data.get("action"),
      label: this.data.get("label")
    };
  }
}
