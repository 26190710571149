import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "room",
    "tally"
  ];

  update() {
    const talliesUpdated = new CustomEvent("tallies-updated", {
      bubbles: true,
      detail: {
        visibleRooms: this.filterDataSets
      }
    });

    this.tallyTargets.forEach(tallyTarget => tallyTarget.dispatchEvent(talliesUpdated));
  }

  get filterDataSets() {
    return this.visibleRoomDataSets.map((dataSet) => {
      return {
        building: dataSet.buildingName,
        roomType: dataSet.filterRoomType,
        capacity: dataSet.filterCapacity,
        location: dataSet.filterLocation,
        furnitureType: dataSet.filterFurnitureType,
        features: dataSet.features
      };
    });
  }

  get visibleRoomDataSets() {
    return this.visibleRooms.map(room => room.dataset);
  }

  get visibleRooms() {
    return this.roomTargets.filter(room => room.hidden === false);
  }
}
