import { Controller } from "stimulus";
const SessionState = require("../models/session_state");

export default class extends Controller {
  static targets = [
    "customCapacityUpdateCheckbox",
    "customMinCapacityInput",
    "customMaxCapacityInput",
    "customCapacityUpdateButton",
    "validationMessages"
  ]

  static values = {
    predefinedRanges: Array
  }

  connect() {
    this.setMinMaxFromState();
    this.validate();
  }

  updateCustomCapacityFilter() {
    const minValue = this.customMinCapacityInputTarget.value;
    const maxValue = this.customMaxCapacityInputTarget.value;

    this.customCapacityUpdateButtonTarget.dataset.googleAnalyticsEventsLabel = `Updated capacity range: "${minValue} to ${maxValue}"`;

    this.customCapacityUpdateCheckboxTarget.dataset.inputName = `${minValue}:${maxValue}`;
    this.customCapacityUpdateCheckboxTarget.checked = true;
  }

  reset() {
    this.customMinCapacityInputTarget.value = null;
    this.customMaxCapacityInputTarget.value = null;
    this.customCapacityUpdateCheckboxTarget.dataset.inputName = "min:max";
    this.customCapacityUpdateCheckboxTarget.checked = false;
  }

  validate() {
    this.validateMin();
    this.validateMax();
    this.validateAll();
  }

  validateMin() {
    if (this.minIsNotNumeric) {
      this.setWarningBorder(this.customMinCapacityInputTarget);
    } else {
      this.clearWarningBorder(this.customMinCapacityInputTarget);
    }
  }

  validateMax() {
    if (this.maxIsNotNumeric) {
      this.setWarningBorder(this.customMaxCapacityInputTarget);
    } else {
      this.clearWarningBorder(this.customMaxCapacityInputTarget);
    }
  }

  setWarningBorder(element) {
    element.classList.add("invalid-input");
  }

  clearWarningBorder(element) {
    element.classList.remove("invalid-input");
  }

  validateAll() {
    if (this.minIsNotNumeric || this.maxIsNotNumeric) {
      this.validationMessagesTarget.innerHTML = "<p>Please enter numbers only.</p>";
      this.validationMessagesTarget.hidden = false;
      this.customCapacityUpdateButtonTarget.disabled = true;
    } else {
      this.validationMessagesTarget.innerHTML = "";
      this.validationMessagesTarget.hidden = true;
      this.customCapacityUpdateButtonTarget.disabled = false;
    }
  }

  get minIsNotNumeric() {
    return isNaN(this.customMinCapacityInputTarget.value);
  }

  get maxIsNotNumeric() {
    return isNaN(this.customMaxCapacityInputTarget.value);
  }

  setMinMaxFromState() {
    const sessionState = (new SessionState()).filters;
    if (this.hasCustomMinMaxCapacity(sessionState)) {
      this.customMinCapacityInputTarget.value = this.minFromSession(sessionState);
      this.customMaxCapacityInputTarget.value = this.maxFromSession(sessionState);
      this.updateCustomCapacityFilter();
    }
  }

  hasCustomMinMaxCapacity(sessionState) {
    return sessionState.capacity.length === 1 &&
      this.predefinedRangesValue.includes(sessionState.capacity[0]) === false;
  }

  minFromSession(sessionState) {
    return sessionState.capacity[0].split(":")[0];
  }

  maxFromSession(sessionState) {
    return sessionState.capacity[0].split(":")[1];
  }
}
