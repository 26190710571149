import { Controller } from "stimulus";
const RoomAttribute = require("../models/room_attribute");
const SessionState = require("../models/session_state");
const queryString = require("query-string");

export default class extends Controller {
  static targets = ["filterSet"]

  initialize() {
    this.sessionState = new SessionState();
    this.filters = this.sessionState.filters;

    if (this.sessionState.persisted) {
      this.buildQueryString();
      this.updateState();
      this.updateFilters();
    }

    this.clearAllFiltersElements.forEach(element => element.addEventListener("click", this.reset.bind(this)));
  }

  update(event) {
    this.filters[event.detail.name] = event.detail.state;
    this.buildQueryString();
    this.updateState();
    this.sessionState.saveSessionState(this.filters);
  }

  updateState() {
    this.rooms.forEach((room, i) => {
      const meetsRoomTypeCriteria = room.meetsCriteriaFor("type", this.filters.roomType);
      const meetsLocationCriteria = room.meetsCriteriaFor("location", this.filters.location);
      const meetsCapacityCriteria = room.meetsCriteriaFor("capacity", this.filters.capacity);
      const meetsFurnitureTypeCriteria = room.meetsCriteriaFor("furnitureType", this.filters.furnitureType);
      const meetsBuildingCriteria = room.meetsCriteriaFor("building", this.filters.building);
      const meetsFeatureCriteria = room.meetsCriteriaFor("features", this.filters.feature);

      if (meetsRoomTypeCriteria && meetsLocationCriteria && meetsFurnitureTypeCriteria && meetsBuildingCriteria && meetsCapacityCriteria && meetsFeatureCriteria) {
        room.show();
      } else {
        room.hide();
      }
    });

    const roomsChanged = new CustomEvent("rooms-changed", { bubbles: true });
    this.element.dispatchEvent(roomsChanged);
  }

  updateFilters() {
    this.filters = {
      ...this.filters,
      ...this.readQueryString()
    };
    this.sessionState.saveSessionState(this.filters);

    const filtersUpdated = new CustomEvent("filters-updated", { bubbles: true });
    this.element.dispatchEvent(filtersUpdated);
    this.checkFiltersWithValues();
  }

  checkFiltersWithValues() {
    for (const filterValues of Object.values(this.filters)) {
      filterValues.forEach((filterValue) => {
        const checkbox = document.querySelector(`[data-input-name="${filterValue}"]`);
        const openMenusEvent = new CustomEvent("open-menus", {
          bubbles: true,
          detail: {
            filters: this.filters
          }
        });
        if (checkbox !== null) {
          checkbox.checked = true;
          checkbox.dispatchEvent(openMenusEvent);
        }
      });
    };
  }

  readQueryString() {
    const originalQueryString = window.location.search;
    const parsedQuery = queryString.parse(originalQueryString);

    for (const [filter, value] of Object.entries(parsedQuery)) {
      if (typeof parsedQuery[filter] === "string") {
        parsedQuery[filter] = [value];
      };
    }

    return parsedQuery;
  }

  buildQueryString() {
    const filterQueryString = queryString.stringify(this.filters);
    const newURL = `${window.location.protocol}//${window.location.host}/?${filterQueryString}`;
    window.history.replaceState(window.history.state, "", newURL);
  }

  hide() {
    this.element.hidden = true;
  }

  show() {
    this.element.hidden = false;
  }

  reset() {
    const filterReset = new CustomEvent("filter-reset", { bubbles: true });
    this.filterSetTargets.forEach((target) => target.dispatchEvent(filterReset));
  }

  get rooms() {
    return RoomAttribute.buildFromNodeList(document.body.querySelectorAll(".room-listing"));
  }

  get clearAllFiltersElements() {
    return document.querySelectorAll("[data-filter-add-event-listener-target='reset']");
  }
}
