import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "roomDetail",
    "roomList",
    "filters",
    "terminology",
    "section"
  ];

  route(event) {
    if (location.hash.match(/#/)) {
      location = location.hash.replace(/#\//, "");
    }
  }

  checkQueryString(event) {
    if (this.hasQueryString) {
      const newFiltersEvent = new CustomEvent("new-filters", { bubbles: true });
      this.sectionTargets.forEach(section => section.dispatchEvent(newFiltersEvent));
    }
  }

  get hasQueryString() {
    return location.search.replace(/\?/, "").length > 0;
  }
}
