import { Controller } from "stimulus";
const googleAnalyticsEvent = require("../models/google_analytics_event");

export default class extends Controller {
  static targets = [
    "filters"
  ];

  trackEvent(event) {
    googleAnalyticsEvent.trackEvent(this.params);
  }

  get params() {
    return {
      category: this.category,
      action: this.action,
      label: this.label
    };
  }

  get action() {
    if (this.expanding) {
      return "Expand";
    } else {
      return "Contract";
    }
  }

  get category() {
    return "Filter";
  }

  get label() {
    return this.data.get("label");
  }

  get collapsing() {
    return this.filtersTarget.classList.contains("show");
  }

  get expanding() {
    return !this.collapsing;
  }
}
