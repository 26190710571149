class RoomAttribute {
  static buildFromNodeList(elems) {
    return Array.from(elems).map(elem => {
      const featuresJsonString = elem.dataset.features;
      const features = JSON.parse(featuresJsonString);
      const roomAttrs = {
        ...features,
        id: elem.dataset.id,
        capacity: elem.dataset.filterCapacity,
        type: elem.dataset.filterRoomType,
        location: elem.dataset.filterLocation,
        furnitureType: elem.dataset.filterFurnitureType,
        building: elem.dataset.buildingName,
        name: elem.dataset.filterName,
        mapUri: elem.dataset.mapUri,
        instructionsUri: elem.dataset.instructionsUri,
        printLayoutUri: elem.dataset.printLayoutUri,
        scheduleUri: elem.dataset.scheduleUri,
        elem: elem
      };

      return new RoomAttribute(roomAttrs);
    });
  }

  constructor(attrs) {
    this.id = attrs.id;
    this.features = attrs.features;
    this.capacity = attrs.capacity;
    this.type = attrs.type;
    this.location = attrs.location;
    this.furnitureType = attrs.furnitureType;
    this.building = attrs.building;
    this.name = attrs.name;
    this.elem = attrs.elem;
    this.mapUri = attrs.mapUri;
    this.instructionsUri = attrs.instructionsUri;
    this.printLayoutUri = attrs.printLayoutUri;
    this.scheduleUri = attrs.scheduleUri;
  }

  hide() {
    this.elem.hidden = true;
  }

  show() {
    this.elem.hidden = false;
  }

  meetsCriteriaFor(attrName, valueCollection) {
    if (valueCollection.length === 0) {
      return true;
    } else if (valueCollection.length === 1 && valueCollection[0] === "Any" ) {
      return true
    } else {
      return this.hasAttribute(attrName, valueCollection);
    }
  }

  hasAttribute(attrName, valueCollection) {
    if (attrName === "features") {
      return this.hasFeatures(valueCollection);
    } else if (attrName === "capacity") {
      return this.withinCapacityRange(valueCollection);
    } else {
      return valueCollection.includes(this[attrName]);
    }
  }

  hasFeatures(features) {
    const matches = this.findFeatureMatches(features);
    return matches.length === features.length;
  }

  withinCapacityRange(desiredCapacityRanges) {
    return desiredCapacityRanges.some(range => {
      const lowEnd = this.parseLowEnd(range);
      const highEnd = this.parseHighEnd(range);
      return lowEnd <= this.capacity && this.capacity <= highEnd;
    });
  }

  parseLowEnd(range) {
    const possibleLowEnd = parseInt(range.split(":")[0]);
    if (Number.isNaN(possibleLowEnd)) {
      return 0;
    } else {
      return possibleLowEnd;
    }
  }

  parseHighEnd(range) {
    const possibleHighEnd = parseInt(range.split(":")[1]);
    if (Number.isNaN(possibleHighEnd)) {
      return Number.MAX_SAFE_INTEGER;
    } else {
      return possibleHighEnd;
    }
  }

  findFeatureMatches(features) {
    return features.filter(feature => this.features.includes(feature));
  }
}

module.exports = RoomAttribute;
