import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "checkbox",
    "tallyElem"
  ];

  static values = {
    filterName: String
  }

  update(event) {
    this.tallyElemTarget.innerHTML = `(${this.tally(event.detail.visibleRooms)})`;
  }

  tally(visibleRoomData) {
    return visibleRoomData.filter((room) => {
      if (this.filterNameValue === "feature") {
        const featureData = JSON.parse(room.features).features;
        return featureData.includes(this.filterValue);
      } else if (this.filterNameValue === "capacity") {
        const capacityRange = this.filterValue;
        const capacityLowEnd = parseInt(capacityRange.split(":")[0]);
        const capacityHighEnd = parseInt(capacityRange.split(":")[1]);
        return (room.capacity >= capacityLowEnd) && (room.capacity <= capacityHighEnd);
      } else {
        return room[this.filterNameValue] === this.filterValue;
      }
    }).length;
  }

  get filterValue() {
    return this.checkboxTarget.dataset.inputName;
  }
}
