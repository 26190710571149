class GoogleAnalyticsEvent {
  static trackEvent(params) {
    gtag("event", params.action, {
      "event_category": params.category,
      "event_label": params.label
    });
  }
}

module.exports = GoogleAnalyticsEvent;
