import { Controller } from "stimulus";

export default class extends Controller {
  show() {
    this.element.hidden = false;
  }

  hide() {
    this.element.hidden = true;
  }
}
