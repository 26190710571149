import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    text: String,
    inputText: String
  }

  remove() {
    this.dispatchEvent();
    this.element.remove();
  }

  dispatchEvent() {
    const tagClosedEvent = new CustomEvent("tag-closed", {
      bubbles: true,
      detail: {
        filterValue: this.textValue,
        inputText: this.inputTextValue
      }
    });

    const queryString = `[data-input-name="${this.inputTextValue}"]`;
    const input = document.querySelector(queryString);
    input.dispatchEvent(tagClosedEvent);
  }
}
