import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "heroImage"
  ];

  connect(event) {
    $("#hero-img-modal").on("show.bs.modal", (event) => {
      this.show(event.relatedTarget.dataset.imgUri, event.relatedTarget.dataset.altText);
    });

    this.currentVisibleImgSelector = document.querySelector(".hero-image.first").dataset.currentPhotoSelector;
  }

  previous(event) {
    event.preventDefault();
    const newVisibleImgSelector = this.imgElements[this.currentVisibleImgSelector].pred;
    const imgToHide = document.querySelector(this.currentVisibleImgSelector);
    const imgToDisplay = document.querySelector(newVisibleImgSelector);
    this.currentVisibleImgSelector = `#${imgToDisplay.id}`;
    imgToDisplay.classList.remove("d-none");
    imgToHide.classList.add("d-none");
  }

  next(event) {
    event.preventDefault();
    const newVisibleImgSelector = this.imgElements[this.currentVisibleImgSelector].succ;
    const imgToHide = document.querySelector(this.currentVisibleImgSelector);
    const imgToDisplay = document.querySelector(newVisibleImgSelector);
    this.currentVisibleImgSelector = `#${imgToDisplay.id}`;
    imgToDisplay.classList.remove("d-none");
    imgToHide.classList.add("d-none");
  }

  show(uri, altText) {
    const modalBody = document.querySelector(".modal-body");
    modalBody.innerHTML = `<img src="${uri}" alt="${altText}">`;
  }

  loadHeroImageFromThumbnail(event) {
    event.preventDefault();
    const newVisibleImgSelector = event.target.dataset.heroImgSelector;
    const imgToHide = document.querySelector(this.currentVisibleImgSelector);
    const imgToDisplay = document.querySelector(newVisibleImgSelector);
    this.currentVisibleImgSelector = `#${imgToDisplay.id}`;

    if (imgToHide.id !== imgToDisplay.id) {
      imgToDisplay.classList.remove("d-none");
      imgToHide.classList.add("d-none");
    }
  }

  get imgElements() {
    const obj = {};
    this.heroImageTargets.forEach((element) => {
      const key = `#${element.id}`;
      obj[key] = {
        pred: element.dataset.previousPhotoSelector,
        succ: element.dataset.nextPhotoSelector
      };
    });

    return obj;
  }
}
