import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "section",
    "show"
  ]

  toggle() {
    this.sectionTarget.classList.toggle("toggle-filter");
    this.showTarget.innerText = this.setShowTarget();
  }

  setShowTarget() {
    return (this.showTargetValue === "Show Filters" ? "Hide Filters" : "Show Filters");
  }

  get showTargetValue() {
    return this.showTarget.innerText;
  }
}
